import { FC, Fragment } from 'react';
import classNames from 'classnames';
import styles from './contacts.module.scss';
import { Typography } from '@/shared/ui/typography';
import { IContactsProps } from '@/widgets/page/contacts/interfaces';

export const Contacts: FC<IContactsProps> = ({ officeAddress, salesAddress, salesWorkTime, officeWorkTime, phone }) => {

  return (
        <div className={classNames(styles.container)}>
            <div className={classNames(styles.col, styles.column)}>
                <span className={styles.title}>Центр продаж:</span>
                <Typography as="span" className={styles.gold}><a href="https://yandex.ru/profile/1287878858" className={styles.gold} target="_blank">{salesAddress}</a></Typography>
            </div>
            <div className={classNames(styles.grid, styles.mb, styles.column)}>
                {salesWorkTime?.map((workTime, index) => {
                  const [days, time] = workTime.split(' ');
                  return (
                        <Fragment key={index}>
                            <Typography as="span" className={styles.gray}>{days}</Typography>
                            <Typography as="span" className={styles.gray}>{time}</Typography>
                        </Fragment>
                  );
                })}
            </div>
            <div className={classNames(styles.col, styles.column)}>
                <span className={styles.title}>Офис продаж:</span>
                <Typography as="span" className={styles.gold}><a href="https://yandex.ru/profile/69552867839" className={styles.gold} target="_blank">{officeAddress}</a></Typography>
            </div>
            <div className={classNames(styles.grid, styles.mb, styles.column)}>
                {officeWorkTime?.map((workTime, index) => {
                  const [days, time] = workTime.split(' ');
                  return (
                        <Fragment key={index}>
                            <Typography as="span" className={styles.gray}>{days}</Typography>
                            <Typography as="span" className={styles.gray}>{time}</Typography>
                        </Fragment>
                  );
                })}
            </div>
            <div className={classNames(styles.col, styles.center)}>
                <span className={styles.title}>Номер телефона:</span>
                <a className={classNames(styles.text, styles.gold)} href={`tel:${phone?.replace(/ |\(|-|\)|/g, '')}`}>{phone}</a>
            </div>
        </div>
  );
};