'use client';

import { useEffect } from 'react';
import { Metadata } from 'next';
import Link from 'next/link';
import { ContactsBlock } from '@/entities/contacts-block';
import { GlobalFooter } from '@/entities/global-footer';
import { Header } from '@/entities/header';
import { Breadcrumbs } from '@/shared/ui/breadcrumbs';
import { Button } from '@/shared/ui/button';
import { NotFound as NotFoundIcon } from '@/shared/ui/icons/NotFound';
import { Typography } from '@/shared/ui/typography';

export const metadata: Metadata = {
  title: 'Страница не найдена',
};

const NotFound = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
        <>
            <Header />
            <section className="error-page">
                <aside className="error-breadcrumbs">
                    <Breadcrumbs
                        items={[
                            <Link key="home" href="/">Главная</Link>,
                            '404',
                        ]}
                    />
                </aside>
                <div className="error-container">
                    <div className="error-icon">
                        <NotFoundIcon />
                    </div>
                    <div>
                        <header className="error-header">
                            <Typography as="h3" variant="h3">Извините, такая страница не существует.</Typography>
                        </header>
                        <footer className="error-footer">
                            <Button type="secondary" href="/">перейти на главную</Button>
                        </footer>
                    </div>
                </div>
                <ContactsBlock />
            </section>
            <GlobalFooter />
        </>
  );
};

export default NotFound;