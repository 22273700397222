'use client';

import classNames from 'classnames';
import Link from 'next/link';
import styles from './breadcrumbs.module.scss';
import { IBreadcrumbsProps } from './interfaces';
import { ArrowRight } from '@/shared/ui/icons/ArrowRight';

export const Breadcrumbs = ({ items, backLabel, backUrl }: IBreadcrumbsProps) => {

  return (
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.items, backLabel && backUrl && styles.responsive)}>
          {items.map((item, index) => (
              <div key={index} className={classNames(styles.item, index !== items.length - 1 && styles['item-parent'])}>
                {index === items.length - 1 ? (
                    <span className={styles.text}>{item}</span>
                ) : item}
                {index !== items.length - 1 && (
                    <ArrowRight />
                )}
              </div>
          ))}
        </div>
        {backLabel && backUrl && (
            <Link href={backUrl} className={styles.back}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M11.29 17.7099L6.28999 12.7099C6.19895 12.6148 6.12758 12.5027 6.07999 12.3799C5.97997 12.1365 5.97997 11.8634 6.07999 11.6199C6.12758 11.4972 6.19895 11.385 6.28999 11.2899L11.29 6.28994C11.3832 6.1967 11.4939 6.12274 11.6157 6.07228C11.7376 6.02182 11.8681 5.99585 12 5.99585C12.2663 5.99585 12.5217 6.10164 12.71 6.28994C12.8983 6.47824 13.0041 6.73364 13.0041 6.99994C13.0041 7.26624 12.8983 7.52164 12.71 7.70994L9.40999 10.9999L17 10.9999C17.2652 10.9999 17.5196 11.1053 17.7071 11.2928C17.8946 11.4804 18 11.7347 18 11.9999C18 12.2652 17.8946 12.5195 17.7071 12.707C17.5196 12.8946 17.2652 12.9999 17 12.9999L9.40999 12.9999L12.71 16.2899C12.8037 16.3829 12.8781 16.4935 12.9289 16.6154C12.9796 16.7372 13.0058 16.8679 13.0058 16.9999C13.0058 17.132 12.9796 17.2627 12.9289 17.3845C12.8781 17.5064 12.8037 17.617 12.71 17.7099C12.617 17.8037 12.5064 17.8781 12.3846 17.9288C12.2627 17.9796 12.132 18.0057 12 18.0057C11.868 18.0057 11.7373 17.9796 11.6154 17.9288C11.4936 17.8781 11.383 17.8037 11.29 17.7099Z" fill="#C59669"/>
              </svg>
              {backLabel}
            </Link>
        )}
      </div>
  );
};